import { render, staticRenderFns } from "./GifDialog.vue?vue&type=template&id=61e01bcc&scoped=true&"
import script from "./GifDialog.vue?vue&type=script&lang=js&"
export * from "./GifDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61e01bcc",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VDialog,VIcon,VImg})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Cam\\projects\\netcountable-app\\truple\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('61e01bcc')) {
      api.createRecord('61e01bcc', component.options)
    } else {
      api.reload('61e01bcc', component.options)
    }
    module.hot.accept("./GifDialog.vue?vue&type=template&id=61e01bcc&scoped=true&", function () {
      api.rerender('61e01bcc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/setup/GifDialog.vue"
export default component.exports