var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticStyle: { "max-width": "900px" } },
    [
      _c(
        "v-card",
        { staticClass: "ma-3" },
        [
          _c("v-card-title", [_vm._v("\n      Android 9+ Devices\n    ")]),
          _c(
            "v-card-text",
            [
              _vm._v(
                "\n      The app will automatically detect once you've adjusted your devices DNS settings and will automatically trigger email alerts if the filter is disabled.  If you wish to prevent the ability to disable the filter, enable the Block Tamper setting from the "
              ),
              _c("router-link", { attrs: { to: "devices" } }, [
                _vm._v("device settings")
              ]),
              _vm._v(" page.\n\n      "),
              _c("div", { staticClass: "headline mt-1" }, [
                _vm._v("Available Filters")
              ]),
              _c("ul", [
                _c("li", [
                  _c("b", [_vm._v("adult-filter-dns.cleanbrowsing.org")]),
                  _vm._v(" - Blocks pornography, phishing, malware.")
                ]),
                _c("li", [
                  _c("b", [_vm._v("family-filter-dns.cleanbrowsing.org")]),
                  _vm._v(
                    " - Blocks everything the adult filter blocks, plus proxies, VPNs, Reddit, and forces Google, Bing, and Youtube to Safe Mode."
                  )
                ]),
                _c("li", [
                  _c("b", [
                    _vm._v("custom9efcd2777c2dd5cf.dot.cleanbrowsing.org")
                  ]),
                  _vm._v(
                    " - Blocks everything the family filter blocks, plus social media, dating, file sharing, search engines that don't support restricted search.  Forces restricted search on unblocked search engines."
                  )
                ])
              ]),
              _c("div", { staticClass: "headline mt-2" }, [_vm._v("Samsung")]),
              _c("ol", [
                _c("li", [
                  _vm._v("\n          Open the 'Settings' App\n        ")
                ]),
                _c("li", [
                  _vm._v("\n          Select 'Connections'\n        ")
                ]),
                _c("li", [
                  _vm._v(
                    "\n          Select 'More connection settings'\n        "
                  )
                ]),
                _c("li", [
                  _vm._v("\n          Select 'Private DNS'\n        ")
                ]),
                _c("li", [
                  _vm._v(
                    "\n          Select 'Private DNS provider hostname'\n        "
                  )
                ]),
                _c("li", [
                  _vm._v(
                    "\n          Enter in your filter of choice (ie, adult-filter-dns.cleanbrowsing.org)\n        "
                  )
                ]),
                _c("li", [_vm._v("Select 'Save'")])
              ]),
              _c("div", { staticClass: "headline mt-2" }, [
                _vm._v("Stock Android")
              ]),
              _c("ol", [
                _c("li", [
                  _vm._v("\n          Open the 'Settings' App\n        ")
                ]),
                _c("li", [
                  _vm._v("\n          Select 'Network & internet'\n        ")
                ]),
                _c("li", [_vm._v("\n          Select 'Advanced'\n        ")]),
                _c("li", [
                  _vm._v("\n          Select 'Private DNS'\n        ")
                ]),
                _c("li", [
                  _vm._v(
                    "\n          Select 'Private DNS provider hostname'\n        "
                  )
                ]),
                _c("li", [
                  _vm._v(
                    "\n          Enter in your filter of choice (ie, adult-filter-dns.cleanbrowsing.org)\n        "
                  )
                ]),
                _c("li", [_vm._v("Select 'Save'")])
              ]),
              _c("div", { staticClass: "mt-3 caption font-italic" }, [
                _vm._v("Need help?  Contact "),
                _c("a", { attrs: { href: "mailto:support@truple.io" } }, [
                  _vm._v("support@truple.io")
                ]),
                _vm._v(" with any questions.")
              ]),
              _c("br"),
              _vm._v("\n\n      Alternatively, if you wish to use our "),
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "https://play.google.com/store/apps/details?id=com.camhart.pornblocker",
                    target: "_blank"
                  }
                },
                [_vm._v("dedicated web filter app")]
              ),
              _vm._v(
                ", you can do so. To use the premium features of the filter app, click the Screenshot Accountability Login button, then login with your Screenshot Accountability credentials. "
              ),
              _c("br"),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function($event) {
                      return _vm.showGif(
                        "Android Web Filter",
                        "/static/img/setup/webfilter.gif"
                      )
                    }
                  }
                },
                [_vm._v("View Instructions")]
              ),
              _vm.gif.show
                ? _c("gif-dialog", {
                    attrs: {
                      title: _vm.gif.title,
                      src: _vm.gif.src,
                      show: _vm.gif.show
                    },
                    on: { close: _vm.closeGifDialog }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "ma-3" },
        [
          _c("v-card-title", [_vm._v("\n      iPhone/iPad\n    ")]),
          _c("v-card-text", [
            _vm._v(
              "\n      Included by default when you setup monitoring.\n    "
            )
          ])
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "ma-3" },
        [
          _c("v-card-title", [_vm._v("\n      Chrome Filter\n    ")]),
          _c("v-card-text", [
            _vm._v("\n      Truple's free "),
            _c(
              "a",
              {
                attrs: {
                  href:
                    "https://blog.truple.io/2022/07/01/chrome-web-filter.html",
                  target: "_blank"
                }
              },
              [_vm._v("Chrome web filter")]
            ),
            _vm._v(
              " will filter pornographic content from within Google Chrome (and Microsoft Edge) browsers on Windows, MacOS, Linux, and Chromebook.\n    "
            )
          ])
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "ma-3" },
        [
          _c("v-card-title", [_vm._v("\n      Windows\n    ")]),
          _c(
            "v-card-text",
            [
              _c("v-list-item", { attrs: { dense: "" } }, [
                _vm._v("\n        •"),
                _c("span", { staticClass: "font-weight-bold pl-1 pr-1" }, [
                  _vm._v("(Recommended)")
                ]),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://cleanbrowsing.org/guides/windows",
                      target: "_blank"
                    }
                  },
                  [_vm._v("Cleanbrowsing.org's guide for Windows")]
                )
              ]),
              _c("v-list-item", { attrs: { dense: "" } }, [
                _vm._v("\n        •"),
                _c(
                  "a",
                  {
                    staticClass: "pl-1",
                    attrs: {
                      href:
                        "https://support.opendns.com/hc/en-us/sections/206253647",
                      target: "_blank"
                    }
                  },
                  [_vm._v("OpenDNS")]
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "ma-3" },
        [
          _c("v-card-title", [_vm._v("\n      MacOS\n    ")]),
          _c(
            "v-card-text",
            [
              _c("v-list-item", { attrs: { dense: "" } }, [
                _vm._v("\n        •"),
                _c("span", { staticClass: "font-weight-bold pl-1 pr-1" }, [
                  _vm._v("(Recommended)")
                ]),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://cleanbrowsing.org/guides/macos",
                      target: "_blank"
                    }
                  },
                  [_vm._v("Cleanbrowsing.org's guide for MacOS")]
                )
              ]),
              _c("v-list-item", { attrs: { dense: "" } }, [
                _vm._v("\n        •"),
                _c(
                  "a",
                  {
                    staticClass: "pl-1",
                    attrs: {
                      href:
                        "https://support.opendns.com/hc/en-us/sections/206253647",
                      target: "_blank"
                    }
                  },
                  [_vm._v("OpenDNS")]
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "ma-3" },
        [
          _c("v-card-title", [_vm._v("\n      Chrome OS / Chromebooks\n    ")]),
          _c("v-card-text", [
            _c("div", [
              _vm._v(
                "Follow the steps below to enable filtering on Chrome OS.  There are two options, simple or advanced.  Advanced allows you to choose between 3 different filter types.  Simple only offers one."
              )
            ]),
            _c("br"),
            _c("h3", [_vm._v("Simple filtering")]),
            _c(
              "ol",
              [
                _c("li", [_vm._v("Navigate to chrome://settings/security")]),
                _c("li", [
                  _vm._v('Scroll down until you find "Use secure DNS"')
                ]),
                _c("li", [_vm._v('Select "Cleanbrowsing.org (Family Filter)')]),
                _c("v-img", {
                  attrs: {
                    src: "/static/img/chromeos/dns/chrome.dns.simple.png"
                  }
                })
              ],
              1
            ),
            _c("br"),
            _c("h3", [_vm._v("Advanced filtering")]),
            _c("ol", [
              _c("li", [_vm._v("Open up Chrome browser")]),
              _c("li", [_vm._v("Navigate to chrome://flags")]),
              _c("li", [_vm._v('Search for "dns"')]),
              _c("li", [
                _vm._v(
                  'Switch "Support for HTTPSSVC records in DNS" from "Default" to "Enabled"'
                )
              ]),
              _c("li", [_vm._v("Restart your Chromebook")]),
              _c("li", [_vm._v("Open up Chrome browser")]),
              _c("li", [_vm._v("Navigate to chrome://settings/security")]),
              _c("li", [_vm._v('Scroll down until you find "Use secure DNS"')]),
              _c("li", [_vm._v('Enable "With Custom"')]),
              _c(
                "li",
                [
                  _vm._v(
                    "\n          Enter in one of the following values:\n          "
                  ),
                  _c("ul", [
                    _c("li", [
                      _c("b", [
                        _vm._v(
                          "https://doh.cleanbrowsing.org/doh/adult-filter/"
                        )
                      ]),
                      _vm._v(" - Blocks pornography, phishing, malware.")
                    ]),
                    _c("li", [
                      _c("b", [
                        _vm._v(
                          "https://doh.cleanbrowsing.org/doh/family-filter/"
                        )
                      ]),
                      _vm._v(
                        " - Blocks everything the adult filter blocks, plus proxies, VPNs, Reddit, and forces Google, Bing, and Youtube to Safe Mode."
                      )
                    ]),
                    _c("li", [
                      _c("b", [
                        _vm._v(
                          "https://doh.cleanbrowsing.org/doh/custom-filter/9efc:d277:7c2d:d5cf/"
                        )
                      ]),
                      _vm._v(
                        " - Blocks everything the family filter blocks, plus social media, dating, file sharing, search engines that don't support restricted search.  Forces restricted search on unblocked search engines."
                      )
                    ])
                  ]),
                  _c("v-img", {
                    attrs: {
                      src: "/static/img/chromeos/dns/chrome.dns.advanced.png"
                    }
                  })
                ],
                1
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "ma-3" },
        [
          _c("v-card-title", [_vm._v("\n      Linux\n    ")]),
          _c(
            "v-card-text",
            [
              _c("v-list-item", { attrs: { dense: "" } }, [
                _vm._v("\n        •"),
                _c("span", { staticClass: "font-weight-bold pl-1 pr-1" }, [
                  _vm._v("(Recommended)")
                ]),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://cleanbrowsing.org/guides/linux",
                      target: "_blank"
                    }
                  },
                  [_vm._v("Cleanbrowsing.org's guide for Linux")]
                )
              ]),
              _c("v-list-item", { attrs: { dense: "" } }, [
                _vm._v("\n        •"),
                _c(
                  "a",
                  {
                    staticClass: "pl-1",
                    attrs: {
                      href:
                        "https://support.opendns.com/hc/en-us/sections/206253647",
                      target: "_blank"
                    }
                  },
                  [_vm._v("OpenDNS")]
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "ma-3" },
        [
          _c("v-card-title", [
            _vm._v("\n      (Recommended)\n      Home Routers\n    ")
          ]),
          _c("v-card-subtitle", [
            _vm._v(
              "\n      Protect your entire home Wifi, including Smart TVs, Xbox, Playstation\n    "
            )
          ]),
          _c(
            "v-card-text",
            [
              _c("v-list-item", { attrs: { dense: "" } }, [
                _vm._v("\n        •"),
                _c("span", { staticClass: "font-weight-bold pl-1 pr-1" }, [
                  _vm._v("(Recommended)")
                ]),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://cleanbrowsing.org/guides/generic-router-dns-change",
                      target: "_blank"
                    }
                  },
                  [_vm._v("Cleanbrowsing.org's guide for Home Routers")]
                )
              ]),
              _c("v-list-item", { attrs: { dense: "" } }, [
                _vm._v("\n        •"),
                _c(
                  "a",
                  {
                    staticClass: "pl-1",
                    attrs: {
                      href:
                        "https://support.opendns.com/hc/en-us/sections/206253627",
                      target: "_blank"
                    }
                  },
                  [_vm._v("OpenDNS's Guide for Home Routers")]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }