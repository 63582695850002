var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "400px", fullscreen: _vm.$store.state.isMobile },
      model: {
        value: _vm.isShown,
        callback: function($$v) {
          _vm.isShown = $$v
        },
        expression: "isShown"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { tile: "" } },
        [
          _c(
            "v-card-title",
            { staticClass: "d-block" },
            [
              _c("span", [_vm._v(_vm._s(_vm.title))]),
              _c(
                "v-btn",
                {
                  staticClass: "float-right",
                  attrs: { icon: "", color: "primary" },
                  on: { click: _vm.close }
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _vm.textSteps
                ? _c("div", {
                    staticClass: "text-body-1 mb-4",
                    domProps: { innerHTML: _vm._s(_vm.textSteps) }
                  })
                : _vm._e(),
              _c("v-img", {
                staticStyle: {
                  border: "1px solid black",
                  "min-height": "200px"
                },
                attrs: { src: _vm.src }
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  attrs: { block: "", color: "primary" },
                  on: { click: _vm.close }
                },
                [_vm._v("Close")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }