<template>
  <v-container style="max-width: 900px;">
    <v-card class="ma-3">
      <v-card-title>
        Android 9+ Devices
      </v-card-title>
      <v-card-text>
        The app will automatically detect once you've adjusted your devices DNS settings and will automatically trigger email alerts if the filter is disabled.  If you wish to prevent the ability to disable the filter, enable the Block Tamper setting from the <router-link to="devices">device settings</router-link> page.

        <div class="headline mt-1">Available Filters</div>
        <ul>
          <li><b>adult-filter-dns.cleanbrowsing.org</b> - Blocks pornography, phishing, malware.</li>
          <li><b>family-filter-dns.cleanbrowsing.org</b> - Blocks everything the adult filter blocks, plus proxies, VPNs, Reddit, and forces Google, Bing, and Youtube to Safe Mode.</li>
          <li><b>custom9efcd2777c2dd5cf.dot.cleanbrowsing.org</b> - Blocks everything the family filter blocks, plus social media, dating, file sharing, search engines that don't support restricted search.  Forces restricted search on unblocked search engines.</li>
          <!--                          <li><b>doh.cleanbrowsing.org</b> - (Same as adult filter) but uses DNS over Https.  Use this if you have ISP related problems with the other filters.</li>-->
        </ul>

        <div class="headline mt-2">Samsung</div>
        <ol>
          <li>
            Open the 'Settings' App
          </li>
          <li>
            Select 'Connections'
          </li>
          <li>
            Select 'More connection settings'
          </li>
          <li>
            Select 'Private DNS'
          </li>
          <li>
            Select 'Private DNS provider hostname'
          </li>
          <li>
            Enter in your filter of choice (ie, adult-filter-dns.cleanbrowsing.org)
          </li>
          <li>Select 'Save'</li>
        </ol>

        <div class="headline mt-2">Stock Android</div>
        <ol>
          <li>
            Open the 'Settings' App
          </li>
          <li>
            Select 'Network & internet'
          </li>
          <li>
            Select 'Advanced'
          </li>
          <li>
            Select 'Private DNS'
          </li>
          <li>
            Select 'Private DNS provider hostname'
          </li>
          <li>
            Enter in your filter of choice (ie, adult-filter-dns.cleanbrowsing.org)
          </li>
          <li>Select 'Save'</li>
        </ol>
        <div class="mt-3 caption font-italic">Need help?  Contact <a href="mailto:support@truple.io">support@truple.io</a> with any questions.</div>

        <br />

        Alternatively, if you wish to use our <a href="https://play.google.com/store/apps/details?id=com.camhart.pornblocker" target="_blank">dedicated web filter app</a>, you can do so. To use the premium features of the filter app, click the Screenshot Accountability Login button, then login with your Screenshot Accountability credentials. <br />
        <v-btn text @click="showGif('Android Web Filter', '/static/img/setup/webfilter.gif')">View Instructions</v-btn>

        <gif-dialog v-if="gif.show" :title="gif.title" :src="gif.src" :show="gif.show" @close="closeGifDialog" />

      </v-card-text>
    </v-card>

    <v-card class="ma-3">
      <v-card-title>
        iPhone/iPad
      </v-card-title>
      <v-card-text>
        Included by default when you setup monitoring.
      </v-card-text>
    </v-card>

    <v-card class="ma-3">
      <v-card-title>
        Chrome Filter
      </v-card-title>
      <v-card-text>
        Truple's free <a href="https://blog.truple.io/2022/07/01/chrome-web-filter.html" target="_blank">Chrome web filter</a> will filter pornographic content from within Google Chrome (and Microsoft Edge) browsers on Windows, MacOS, Linux, and Chromebook.
      </v-card-text>
    </v-card>

    <v-card class="ma-3">
      <v-card-title>
        Windows
      </v-card-title>
      <v-card-text>
        <v-list-item dense>
          •<span class="font-weight-bold pl-1 pr-1">(Recommended)</span><a href="https://cleanbrowsing.org/guides/windows" target="_blank">Cleanbrowsing.org's guide for Windows</a>
        </v-list-item>
        <v-list-item dense>
          •<a class="pl-1" href="https://support.opendns.com/hc/en-us/sections/206253647" target="_blank">OpenDNS</a>
        </v-list-item>
      </v-card-text>
    </v-card>

    <v-card class="ma-3">
      <v-card-title>
        MacOS
      </v-card-title>
      <v-card-text>
        <v-list-item dense>
          •<span class="font-weight-bold pl-1 pr-1">(Recommended)</span><a href="https://cleanbrowsing.org/guides/macos" target="_blank">Cleanbrowsing.org's guide for MacOS</a>
        </v-list-item>
        <v-list-item dense>
          •<a class="pl-1" href="https://support.opendns.com/hc/en-us/sections/206253647" target="_blank">OpenDNS</a>
        </v-list-item>
      </v-card-text>
    </v-card>

    <v-card class="ma-3">
      <v-card-title>
        Chrome OS / Chromebooks
      </v-card-title>
      <v-card-text>

        <div>Follow the steps below to enable filtering on Chrome OS.  There are two options, simple or advanced.  Advanced allows you to choose between 3 different filter types.  Simple only offers one.</div>

        <br />

        <h3>Simple filtering</h3>
        <ol>
          <li>Navigate to chrome://settings/security</li>
          <li>Scroll down until you find "Use secure DNS"</li>
          <li>Select "Cleanbrowsing.org (Family Filter)</li>
          <v-img src="/static/img/chromeos/dns/chrome.dns.simple.png" />
        </ol>

        <br />

        <h3>Advanced filtering</h3>
        <ol>
          <li>Open up Chrome browser</li>
          <li>Navigate to chrome://flags</li>
          <li>Search for "dns"</li>
          <li>Switch "Support for HTTPSSVC records in DNS" from "Default" to "Enabled"</li>
          <li>Restart your Chromebook</li>
          <li>Open up Chrome browser</li>
          <li>Navigate to chrome://settings/security</li>
          <li>Scroll down until you find "Use secure DNS"</li>
          <li>Enable "With Custom"</li>
          <li>
            Enter in one of the following values:
            <ul>
              <li><b>https://doh.cleanbrowsing.org/doh/adult-filter/</b> - Blocks pornography, phishing, malware.</li>
              <li><b>https://doh.cleanbrowsing.org/doh/family-filter/</b> - Blocks everything the adult filter blocks, plus proxies, VPNs, Reddit, and forces Google, Bing, and Youtube to Safe Mode.</li>
              <li><b>https://doh.cleanbrowsing.org/doh/custom-filter/9efc:d277:7c2d:d5cf/</b> - Blocks everything the family filter blocks, plus social media, dating, file sharing, search engines that don't support restricted search.  Forces restricted search on unblocked search engines.</li>
            </ul>

            <v-img src="/static/img/chromeos/dns/chrome.dns.advanced.png" />
          </li>
        </ol>
      </v-card-text>
    </v-card>

    <v-card class="ma-3">
      <v-card-title>
        Linux
      </v-card-title>
      <v-card-text>
        <v-list-item dense>
          •<span class="font-weight-bold pl-1 pr-1">(Recommended)</span><a href="https://cleanbrowsing.org/guides/linux" target="_blank">Cleanbrowsing.org's guide for Linux</a>
        </v-list-item>
        <v-list-item dense>
          •<a class="pl-1" href="https://support.opendns.com/hc/en-us/sections/206253647" target="_blank">OpenDNS</a>
        </v-list-item>
      </v-card-text>
    </v-card>

    <v-card class="ma-3">
      <v-card-title>
        (Recommended)
        Home Routers
      </v-card-title>
      <v-card-subtitle>
        Protect your entire home Wifi, including Smart TVs, Xbox, Playstation
      </v-card-subtitle>
      <v-card-text>
        <v-list-item dense>
          •<span class="font-weight-bold pl-1 pr-1">(Recommended)</span><a href="https://cleanbrowsing.org/guides/generic-router-dns-change" target="_blank">Cleanbrowsing.org's guide for Home Routers</a>
        </v-list-item>
        <v-list-item dense>
          •<a class="pl-1" href="https://support.opendns.com/hc/en-us/sections/206253627" target="_blank">OpenDNS's Guide for Home Routers</a>
        </v-list-item>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import GifDialog from "./setup/GifDialog";

  export default {
    name: "Filter.vue",
    components: {
      GifDialog
    },
    mounted() {

      if(this.$route.query.openAndroidPieInstructions) {
        this.androidPieInstructions = true
      }

    },
    data () {
      return {
        androidPieInstructions: false,
        gif: {
          title: '',
          src: '',
          show: false
        }
      }
    },
    methods: {
      showGif(title, src) {
        this.gif.title = title
        this.gif.src = src
        this.gif.show = true
      },
      closeGifDialog() {
        this.gif.show = false
      }
    }
  }
</script>

<style scoped>

</style>