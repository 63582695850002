<template>
  <v-dialog v-model="isShown" max-width="400px" :fullscreen="$store.state.isMobile">
    <v-card tile>
      <v-card-title class="d-block">
        <span>{{title}}</span>
        <v-btn icon @click="close" color="primary" class="float-right"><v-icon>close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <div v-html="textSteps" v-if="textSteps" class="text-body-1 mb-4" />
        <v-img :src="src" style="border: 1px solid black; min-height: 200px;" />
      </v-card-text>
      <v-card-actions>
        <v-btn block color="primary" @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "GifDialog",
    props: {
      show: {
        type: Boolean,
        default: false
      },
      title: {
        type: String
      },
      src: {
        type: String
      },
      textSteps: {
        type: String
      }
    },
    methods: {
      close() {
        this.$emit('close')
      }
    },
    computed: {
      isShown: {
        get() {
          return this.show
        },
        set() {
          return
        }
      }
    }
  }
</script>

<style scoped>

</style>